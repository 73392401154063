import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

const NakedSaunasCouples = () => (
  <Layout>
    <SEO title="Page two" />
    <h1>Naked Saunas for Couples</h1>
      <div className="entry-content">
          <p>If you want to sauna naked in the UK, you’re out of luck in many venues except during single-gender
              sessions. So, what are the options for a mixed-gender couple who wish to sauna naked together?</p>
          <p>That’s the question one site visitor asked us:</p>
          <p><strong>Paul Dingley</strong> (11 May 2011 at 2:23pm):</p>
          <blockquote><p>My partner and I are trying to find a good stright sauna come spa where we can relax in naked,
              we use to go to the relaxation centre in Bristol but that’s changed it’s policy. Can you help us find a
              spa in or out of a hotel please.<br />
                  Kind regards Paul.</p></blockquote>

          <p>Well, that’s a great question. It is a shame that the Relaxation Centre in Bristol now insists on swimwear
              being worn on couples days, apparently because of inconsiderate behaviour by some users.</p>
          <p>I assume by ‘straight’ you mean a sauna that isn’t a swingers’ club or similar. There are a number of such
              venues and sadly this reinforces the link between mixed nudity and sex.</p>
          <p>The best option I can think of right now is the Clover Spa in Birmingham – <a
              href="http://www.cloverspa.eu">http://www.cloverspa.eu</a>. Although the facilities are not as extensive
              as the ones in Bristol, it does contain a sauna, steam room, hot tub and plunge pool. This is actually a
              naturist hotel and spa facility, but you don’t need to be a card-carrying naturist to attend. You’re
              looking at £40 for a couple though. Note that this is not a couples-only venue.</p>
          <p>If anyone is aware of a good regular couples or mixed session somewhere in Britain that allows nudity,
              please share your knowledge in a comment.</p>
          <p>Another option is to investigate naturist events. What happens with these is that a group of naturists hire
              a venue for an evening, and charge admission. Usually you’ll need to register first with the organiser.
              There can often be a waiting list for single males, so as a mixed couple you will find it easier to get
              in. I happen to know that recently a naturist group actually hired out the Relaxation Centre in Bristol,
              but more often you’ll find that it is council swimming pools that are hired.</p>
          <p>There is an annoying trend for mixed gender social nudity to be available only for members of two extremes
              – sex clubs and naturist clubs. However, it has to be said that there are many members of ‘naturist’
              groups and forums who are actually only interested in sauna/spa and beach nudity, and not being naked all
              the time. So you may find you have more in common with naturists than you’d think.</p>
          <p>There are still a number of spas that do not explicitly require swimwear, where you would definitely be OK
              naked under towels, probably OK topless, and possibly even OK with fully exposed nudity. Anywhere that
              doesn’t explicitly allow mixed nudity is however dependent on the attitude of your fellow bathers. Bear in
              mind that you have some strength in numbers, even as just two people, and that most bathers won’t
              challenge others. For the greatest chance of acceptance, you need to find somewhere with the fewest number
              of people who will insist that you should cover up. In practice, this means a quiet hotel-based venue that
              is not adjacent to a pool. You should however, be prepared to cover up if challenged. Being discreet helps
              of course, and under no circumstances should you appear to be deliberately attempting to shock or upset.
              Exposure is not automatically indecent (although some people will assume it is) so be careful to keep it
              innocent. As new bathers can enter the sauna at any time, you may find it difficult to relax unless you
              are both particularly confident.</p>
          <p>Your final option in Britain is to find a sauna/spa that you can hire for yourselves for a couple of hours
              and set your own rules. For example, Powfoot Spa in Scotland – http://www.powfootspa.co.uk – from £25 for
              two depending on the day and time. You could even share with another couple to bring costs down.</p>
          <p>I hope that these tips help you and your partner. If you’d like to confirm whereabouts you live, we could
              try to narrow the search down a bit.</p>
      </div>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default NakedSaunasCouples
